
"use client";
import React, { useEffect, useRef, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "@/redux/authSlice";
import logo from "../../../../public/hams-Logo-white.webp";
import Login from "@/components/singIn/Login";
import userProfile from "../../../../public/profile.png";
import { usePathname } from "next/navigation";
import globalAxiosURL from "@/hooks/globalAxiosURL";
import { LuMenu } from "react-icons/lu";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import useTranslator from "@/hooks/useTranslator";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState({});

  const dispatch = useDispatch();
  const { isLoggedIn, email } = useSelector((state) => state.auth);
  const pathName = usePathname();
  const axiosURL = globalAxiosURL();
  const menuRef = useRef(null);
  
  const { t } = useTranslation();
  const texts = { txt1: "Home", txt2: "Flights", txt3: "Trip" };
  const translator = useTranslator(texts);

  const links = [
    { href: "/", text: translator.txt1 || texts.txt1 },
    { href: "/flights", text: translator.txt2 || texts.txt2 },
    { href: "/trips", text: translator.txt3 || texts.txt3 },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setShowNavbar(window.scrollY <= lastScrollY || window.scrollY < 100);
      setLastScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  useEffect(() => {
    if (!email) return;
    const fetchUser = async () => {
      const { data } = await axiosURL.get(`/auth/get-me?email=${email}`);
      setUser(data);
    };
    fetchUser();
  }, [email]);

  const handleToggleMenu = () => setIsOpen((prev) => !prev);
  
  return (
    <nav
      className={`bg-[#00703E] fixed top-0 w-full z-[9999] h-[60px] transition-transform duration-200 ${
        showNavbar ? "translate-y-0" : "-translate-y-full"
      }`}
    >
      <div className="p-1 flex items-center justify-between lg:px-16 md:px-10 px-3 mx-auto h-full">
        <div className="flex items-center" ref={menuRef}>
          <button onClick={handleToggleMenu} className="text-white p-2 hidden">
            {isOpen ? <IoClose size={24} /> : <LuMenu size={24} />}
          </button>
          <Link href="/">
            <Image src={logo} alt="Logo" className="w-[80px] md:w-[100px]" />
          </Link>
        </div>
        <div className="hidden md:flex gap-x-3 text-white">
          {links.map(({ href, text }) => (
            <Link
              key={href}
              href={href}
              className={`px-1 text-sm md:text-base ${
                pathName === href ? "font-bold" : "hover:border-b"
              }`}
            >
              {text}
            </Link>
          ))}
        </div>
        <div className="flex items-center space-x-2">
          {isLoggedIn ? (
            <Link href="/dashboard">
              <div className="size-10 rounded-full overflow-hidden">
                <Image
                  className="w-full h-full object-cover"
                  src={user?.profileImage || userProfile}
                  width={50}
                  height={50}
                  alt="User profile"
                />
              </div>
            </Link>
          ) : (
            <Login />
          )}
        </div>
      </div>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50" onClick={handleToggleMenu} />
      )}
    </nav>
  );
};

export default Navbar;